import React from 'react';
import { Col, Row } from 'react-bootstrap';
import QRCode from 'react-qr-code';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import Android from '../../assets/images/android.png';
import IOS from '../../assets/images/ios.png';
import LogoFowloand from '../../assets/images/logo_dowloand.png';
import moblieLeft from '../../assets/images/moblie_1.jpg';
import moblieRingth from '../../assets/images/moblie_2.jpg';
import Layout from '../../layouts';
import DownloadLinks from './downloadFile';
import styles from './remoteScannerDownload.module.scss';
export const RemoteScannerDownload = () => {
  return (
    <Layout>
      <div className={`${styles.wrapper}`}>
        <h1 className={styles.title}>Download Remote Scanner </h1>

        <div className={styles.content}>
          <Row>
            <Col lg='6'>
              <div className={`${styles.contentText} ${styles.contentWrapper}`}>
                <h2>Download Remote Scanner for Desktop</h2>
                <p>For Windows, Mac </p>
                <p className={styles.smaller}>
                  By downloading Remote Scanner, you accept Terms Of Use and
                  Privacy & Cookies.
                </p>
                <DownloadLinks />

                <img
                  src={LogoFowloand}
                  alt='LogoFowloand'
                  className={styles.logoFowloand}
                 
                />
              </div>
            </Col>
            <Col lg='6'>
              <div className={`${styles.contentText} ${styles.contentWrapper} ${styles.contentRight}`}>
                <h2>Download Remote Scanner for Mobile</h2>
                <div className={styles.qrcode}>
                  <QRCode
                    size={256}
                    style={{ height: 'auto', maxWidth: '100px', width: '100%' }}
                    value={'Download Remote Scanner'}
                    viewBox={`0 0 256 256`}
                  />
                  <div className={styles.store}>
                    <Link
                      target='_blank'
                      href='https://play.google.com/store/apps/details?id=megiservices.prod.rmsc'
                    >
                      <img src={IOS} alt='IOS' />
                    </Link>
                    <Link
                      target='_blank'
                      href='https://apps.apple.com/vn/app/remote-scanner/6615085462?l=vi'
                    >
                      <img src={Android} alt='LogoFowloand' />
                    </Link>
                  </div>
                </div>
                <p>Available for Android and iPhone.</p>
                <div className={styles.imageMoblie}>
                  <img src={moblieLeft} alt='moblieLeft' />
                  <img src={moblieRingth} alt='moblieRingth' />
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </Layout>
  );
};
